import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastrService: NbToastrService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authToken = this.authService.getToken();
    request = request.clone({
      setHeaders: {
        Authorization: "Bearer " + authToken
      }
    });
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {

          console.log(error.status, "Error in Interceptor");


          if (error.status === 401 || error.status === 403) {
            this.toastrService.danger('Unauthorized Access! Please login again.', 'Error');
            localStorage.clear();
            setTimeout(() => {
              this.router.navigate(['/auth/login']);
            }, 1600)
            return of(null);
          }
        }
        return throwError(error);
      })
    );
  }
}
