<h1 id="title" class="title">Login</h1>
<p class="sub-title">Hello! Log in with your email.</p>

<form [formGroup]="dynamicForm" (ngSubmit)="submitForm()" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">Email address:</label>
    <input nbInput fullWidth type="email" formControlName="email_id">
  </div>

  <div class="form-control-group">
    <span class="label-with-link">
      <label class="label" for="input-password">Password:</label>
    </span>
    <input nbInput fullWidth type="password" formControlName="password">
  </div>

  <button nbButton fullWidth *ngIf="isLoading" type="button" nbButton status="primary" class="mr-3">
    <i class="fa fa-spinner fa-spin"></i>
  </button>
  <button nbButton fullWidth [disabled]="!dynamicForm.valid" *ngIf="!isLoading" type="submit" nbButton status="primary"
    class="mr-3">
    Log In
  </button>
</form>
