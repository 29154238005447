import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `

    <span class="created-by">
      Masarati Admin © {{date}}
    </span>

    <!-- <span class="created-by">
      Created with ♥ by <b><a href="https://akveo.page.link/8V2f" target="_blank">Resume Builder Admin</a></b> 2024
    </span> -->
    <!-- <div class="socials">
      <a href="/" target="_blank" class="ion ion-social-github"></a>
      <a href="/" target="_blank" class="ion ion-social-facebook"></a>
      <a href="/" target="_blank" class="ion ion-social-twitter"></a>
      <a href="/" target="_blank" class="ion ion-social-linkedin"></a>
    </div> -->
  `,
})
export class FooterComponent {

  date: any = new Date().getFullYear();
}
