import { FormContentLimit } from "./constant/form_content_limit";

export const AdminConstant = {
  title: 'Masarati Admin',
  logout_text: 'Logout',
  currentTheme: 'default',  // default | dark | cosmic | corporate

  table: {
    page: 1,
    limit: 10,
    sort_by: 'createdAt',
    order_by: 'DESC',
  },


  toastr: {
    success: 'Success',
    error: 'Error',
    permission: "You don't have permission to perform this action",
    unauthorized: 'Unauthorized Access! Please login again.',
  },

  spinner: {
    message: 'Please wait...',
  },

  slug: {
    add: 'add',
    edit: 'edit',
    update: 'update',
    delete: 'delete',
    status: 'status',
  },

  button_text: {
    add: 'Add',
    edit: 'Edit',
    update: 'Update',
    delete: 'Delete',
    cancel: 'Cancel',
    back: 'Back',
  },

  role: {
    title: 'Roles & Permission',
    search_text: 'Search Role...',
    add: 'Add Role',
    edit: 'Edit Role',
    update: 'Update Role',
    update_status: 'Update Role Status',
    delete: 'Delete Role',
    delete_confirmation: 'Are you sure you want to delete this role?',
    update_status_confirmation: 'Are you sure you want to update status?',
  },

  admin_users: {
    title: 'Admin Users',
    search_text: 'Search Users...',
    add: 'Add User',
    edit: 'Edit User',
    update: 'Update User',
    update_status: 'Update User Status',
    delete: 'Delete User',
    delete_confirmation: 'Are you sure you want to delete this user?',
    update_status_confirmation: 'Are you sure you want to update status?',
  },

  corporate_users: {
    title: 'Corporate Users',
    search_text: 'Search Users...',
    add: 'Add User',
    edit: 'Edit User',
    update: 'Update User',
    update_status: 'Update User Status',
    delete: 'Delete User',
    delete_confirmation: 'Are you sure you want to delete this user?',
    update_status_confirmation: 'Are you sure you want to update status?',
  },

  resume_end_users: {
    title: 'End Users',
    search_text: 'Search Users...',
    add: 'Add User',
    edit: 'Edit User',
    update: 'Update User',
    update_status: 'Update User Status',
    delete: 'Delete User',
    delete_confirmation: 'Are you sure you want to delete this user?',
    update_status_confirmation: 'Are you sure you want to update status?',
  },

  subscription_management: {
    title: 'Subscription Management',
    search_text: 'Search plan...',
    add: 'Add Plan',
    edit: 'Edit Plan',
    update: 'Update Plan',
    update_status: 'Update Plan Status',
    delete: 'Delete Plan',
    delete_confirmation: 'Are you sure you want to delete this Plan?',
    update_status_confirmation: 'Are you sure you want to update status?',
  },

  form_validation_msg: {
    first_name: {
      required: `First name is required!`,
      min: `First name must be at least ${FormContentLimit.first_name.min} characters!`,
      max: `First name must be at most ${FormContentLimit.first_name.max} characters!`,
    },
    last_name: {
      required: `Last name is required!`,
      min: `Last name must be at least ${FormContentLimit.last_name.min} characters!`,
      max: `Last name must be at most ${FormContentLimit.last_name.max} characters!`,
    },
    company_name: {
      required: `Company name is required!`,
      min: `Company name must be at least ${FormContentLimit.company_name.min} characters!`,
      max: `Company name must be at most ${FormContentLimit.company_name.max} characters!`,
    },
    address: {
      required: `Address is required!`,
      min: `Address must be at least ${FormContentLimit.address.min} characters!`,
      max: `Address must be at most ${FormContentLimit.address.max} characters!`,
    },
    number_of_employee: {
      required: `Number of employee is required!`,
      min: `Number of employee must be at least ${FormContentLimit.number_of_employee.min} characters!`,
      max: `Number of employee must be at most ${FormContentLimit.number_of_employee.max} characters!`,
    },
    company_website_url: {
      required: `Company website url is required!`,
      // min: `Company  must be at least ${FormContentLimit.company_website_url.min} characters!`,
      max: `Company website url must be at most ${FormContentLimit.company_website_url.max} characters!`,
    },
    email_id: {
      required: `Email id is required!`,
      invalid: `Please enter a valid email address!`,
    },
    mobile: {
      required: `Mobile number is required!`,
      invalid: `Please enter a valid mobile number!`,
      max: `Mobile number must be at most ${FormContentLimit.mobile.max} characters!`,
    },
    role_id: {
      required: `Role is required!`,
    },
    password: {
      required: `Password is required!`,
      min: `Password must be at least ${FormContentLimit.password.min} characters!`,
      max: `Password must be at most ${FormContentLimit.password.max} characters!`,
    },

    role_name: {
      required: `Role name is required!`,
      min: `Role name must be at least ${FormContentLimit.role_name.min} characters!`,
      max: `Role name must be at most ${FormContentLimit.role_name.max} characters!`,
    },

    role_description: {
      required: `Role description is required!`,
      min: `Role description must be at least ${FormContentLimit.role_description.min} characters!`,
      max: `Role description must be at most ${FormContentLimit.role_description.max} characters!`,
    },


    // Subscription Plan
    subscription_name: {
      required: `Subscription name is required!`,
      min: `Subscription name must be at least ${FormContentLimit.subscription_name.min} characters!`,
      max: `Subscription name must be at most ${FormContentLimit.subscription_name.max} characters!`,
    },
    subscription_description: {
      required: `Subscription description is required!`,
      min: `Subscription description must be at least ${FormContentLimit.subscription_description.min} characters!`,
      max: `Subscription description must be at most ${FormContentLimit.subscription_description.max} characters!`,
    },
    subscription_price: {
      required: `Subscription price is required!`,
    },
    subscription_validity: {
      required: `Subscription validity is required!`,
    }
  }


}
