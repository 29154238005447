export const FormContentLimit = {
  first_name: {
    min: 3,
    max: 30,
  },
  last_name: {
    min: 3,
    max: 30,
  },
  mobile: {
    max: 16,
  },
  password: {
    min: 6,
    max: 30,
  },
  role_name: {
    min: 3,
    max: 30,
  },
  role_description: {
    min: 3,
    max: 100,
  },

  subscription_name: {
    min: 3,
    max: 30,
  },
  subscription_description: {
    min: 3,
    max: 300,
  },
  company_name: {
    min: 3,
    max: 50,
  },
  company_website_url: {
    min: 3,
    max: 50,
  },
  address: {
    min: 3,
    max: 50,
  },
  number_of_employee: {
    min: 1,
    max: 50,
  },
}
